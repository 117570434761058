* {
	margin: 0;
	padding: 0;
	font-family: "Inter";
}
*:focus {
	outline: none;
}
@font-face {
	font-family: "PaytoneOne";
	src: url(./assets/fonts/PaytoneOne-Regular.ttf);
}
@font-face {
	font-family: "Inter";
	src: url(./assets/fonts/Inter.ttf);
}
@font-face {
	font-family: "Inter-Bold";
	src: url(./assets/fonts/Inter-Bold.ttf);
}
.link {
	text-decoration: none !important;
	background-color: transparent;
	-webkit-text-decoration-skip: objects;
	cursor: pointer;
}
.container {
	max-width: 1296px;
	padding-right: 15px;
	padding-left: 15px;
	margin-right: auto;
	margin-left: auto;
}
.bg-green {
	background-color: #e7faef;
}
.bg-green-dark {
	background-color: #14b082;
}
.bg-gray {
	background-color: #fefbfb;
}
.c-black {
	color: #000000;
}
.c-green {
	color: #14b082;
}
.c-red {
	color: #ff8a8a;
}
.c-white {
	color: #fff !important;
}
.c-orange {
	color: #f96321;
}
.bg-btn-white {
	background-color: #fff;
}
.bg-btn-orange {
	background-color: #f96321;
}
.bg-btn-green {
	background-color: #14b082;
}
.bg-btn-orange-light {
	background-color: #ffc947;
}
.z-index-1 {
	z-index: 1;
	position: relative;
}
.z-index-menu {
	z-index: 99999;
	position: relative;
}
.fixed-top {
	position: fixed;
	top: 0;
	right: 0;
	left: 0;
	z-index: 1030;
}
#home,
#privacy,
#terms,
#signUp {
	margin-top: 90px;
}
#navbarNav {
	z-index: 99999;
}
.closeMenu,
.menuH {
	display: none;
}
.imgBg1 {
	position: absolute;
	top: 0;
	left: 0;
	z-index: 0;
}
.imgBg2 {
	position: absolute;
	top: 0;
	right: 0;
	z-index: 0;
}
a.nav-link {
	color: #12141d;
	font-size: 16px;
	font-weight: bold;
}
.logotipo {
	float: left;
	width: 383px;
	cursor: pointer;
}
dl,
ol,
ul {
	margin-top: 0;
	margin-bottom: 1rem;
}
ul {
	list-style: none;
	padding: 0;
}
li {
	display: list-item;
	text-align: -webkit-match-parent;
	line-height: 35px;
}

.row {
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	margin-right: -15px;
	margin-left: -15px;
	justify-content: center;
}
.navbar {
	position: relative;
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-ms-flex-wrap: wrap;
	flex-wrap: wrap;
	-webkit-box-align: center;
	-ms-flex-align: center;
	align-items: center;
	-webkit-box-pack: justify;
	-ms-flex-pack: justify;
	justify-content: space-between;
}
.navbar-nav {
	display: -webkit-box;
	display: -ms-flexbox;
	display: flex;
	-webkit-box-orient: vertical;
	-webkit-box-direction: normal;
	-ms-flex-direction: column;
	flex-direction: column;
	padding-left: 0;
	margin-bottom: 0;
	list-style: none;
	gap: 5px;
	margin-right: 10px;
}
.nav-link {
	display: block;
	padding: 0.5rem 1rem;
	cursor: pointer;
}
.navbar-nav {
	-webkit-box-orient: horizontal;
	-webkit-box-direction: normal;
	-ms-flex-direction: row;
	flex-direction: row;
}
.paddingHeader {
	padding: 25px 0px;
}

.bg-btn-orange,
.bg-btn-green {
	width: 110px;
	font-size: 18px;
	text-align: center;
	padding: 10px;
	border-radius: 10px;
	color: #fff !important;
	margin: 0 5px;
}

.col1-Banner {
	width: 30%;
	margin-right: 5%;
}
.col2-Banner {
	width: 65%;
}
.col1-Banner,
.col2-Banner {
	display: block;
}
.w-100 {
	width: 100%;
}
.titleBanner {
	font-family: PaytoneOne;
	font-size: 60px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.23;
	letter-spacing: normal;
	color: #12141d;
}
.description {
	opacity: 0.7;
	font-family: Inter;
	font-size: 20px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.6;
	letter-spacing: -0.5px;
	color: #12141d;
	margin: 30px 0px 50px;
}
.btn-green-Banner {
	width: 196px !important;
	height: 55px !important;
	margin: 60px 221px 0 0px !important;
	padding: 14.5px 27px 16.5px 29px !important;
	border-radius: 15px !important;
	color: #fff !important;
	background-color: #14b082 !important;
	text-decoration: none !important;
	cursor: pointer !important;
}
.paddingBanner {
	padding: 70px 0px;
}
.paddingTop {
	padding-top: 70px;
}
.paddingBottom {
	padding-bottom: 70px;
}
.paddingBottom2 {
	padding-bottom: 15px;
}
.bloqGreen {
	width: 150px;
	background-color: #14b082;
	border-radius: 15px;
	padding: 28px 23px;
	position: absolute;
	bottom: 160px;
}
.est {
	font-family: Inter;
	font-size: 36px;
	font-weight: 500;
	font-stretch: normal;
	font-style: normal;
	line-height: 0.72;
	letter-spacing: normal;
	color: #fff;
}
.ano {
	font-family: PaytoneOne;
	font-size: 51.1px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 0.88;
	letter-spacing: normal;
	color: #fff;
	margin-top: 10px;
}
.titleH2 {
	font-family: PaytoneOne;
	font-size: 48px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.13;
	letter-spacing: normal;
	color: #12141d;
	margin-bottom: 24px;
}

.titleH3 {
	font-family: PaytoneOne;
	font-size: 30px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.13;
	letter-spacing: normal;
	color: #12141d;
	margin-bottom: 24px;
}

.titleH4 {
	font-family: PaytoneOne;
	font-size: 20px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.13;
	letter-spacing: normal;
	color: #454545b3;
	margin-bottom: 24px;
}

.parrafo {
	opacity: 0.7;
	font-family: Inter;
	font-size: 16px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.5;
	letter-spacing: normal;
	color: #12141d;
}
.col1-About {
	width: 50%;
	margin-right: 2%;
}
.col2-About {
	width: 48%;
	position: relative;
}
.numberPorcentaje {
	display: flex;
	margin: 48px 0px;
	align-items: center;
}
.linksBox {
	display: inline-flex;
	align-items: center;
	justify-content: center;
}
.numberInPor {
	font-family: PaytoneOne;
	font-size: 42px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.14;
	letter-spacing: normal;
	text-align: right;
}
.txtSpan {
	width: 70px;
	height: 40px;
	margin: 4px 50px 4px 20px;
	opacity: 0.7;
	font-family: Inter;
	font-size: 14px;
	font-weight: normal;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.43;
	letter-spacing: normal;
	color: #18181b;
}
.titleBold {
	color: #12141d;
	font-family: "Inter-Bold";
}
.marginTopBottom {
	margin: 30px 0px;
}
.marginImp {
	margin: 15px 0px !important;
}
.imgNotary {
	width: 233px;
	height: 58px;
}
.linkGreen {
	color: #487c50;
	font-family: "Inter-Bold";
	text-decoration: underline;
	padding: 0 10px;
}
.imgAbout {
	width: 505px;
	height: 503px;
	margin: 80px 0 0 63px;
	border-radius: 30px;
}
.txtcenter {
	text-align: center;
	margin: 0 auto;
	width: 100%;
}
.boxCenter {
	margin: 0 auto;
	width: 722px;
	padding: 20px 0px;
}
.boxCenter2 {
	margin: 0 auto;
	width: 572px;
	padding: 20px 0px;
}
.boxFeat {
	width: 250px;
	margin: 20px 90px;
}
.imgServices {
	width: 60px;
	margin: 0 auto;
	display: block;
}
.col1-Feat {
	width: 40%;
	margin-right: 10%;
}
.col2-Feat {
	width: 45%;
}
ul.iconList li:before {
	content: "";
	display: inline-block;
	height: 21px;
	width: 21px;
	background-image: url(./assets/images/icon-07.png);
	background-size: contain;
	background-repeat: no-repeat;
	margin-left: -2px;
	margin-right: 5px;
}
.col1-Feat2 {
	width: 50%;
	margin-right: 10%;
}
.col2-Feat2 {
	width: 30%;
}
.paddingBloq {
	padding: 30px 0px 10px;
}
.imgArrows {
	width: 100px;
	margin: 30px 50px;
}
.col1-Pricing {
	width: 40%;
	margin-right: 10%;
}
.col2-Pricing {
	width: 48%;
	position: relative;
}
.col1-Contact {
	width: 40%;
	margin-right: 10%;
}
.col2-Contact {
	width: 48%;
	position: relative;
}
.btn-sectionGreen {
	padding: 13.5px 29.5px 13.5px 28.5px;
	border-radius: 10px;
	font-size: 18px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.56;
	letter-spacing: normal;
	text-align: right;
	color: #000 !important;
	font-family: "Inter-Bold";
}
.btn-sectionGreen.link {
	cursor: pointer;
}
.boxPrincing {
	width: 450px;
	padding: 30px 63px 55px 62px;
	border-radius: 15px;
	box-shadow: 0 4px 60px 0 rgba(0, 0, 0, 0.08);
	background-color: #fff;
}
.plan {
	font-family: "Inter";
	font-size: 18px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.33;
	letter-spacing: normal;
	color: #808080;
}
.signingService {
	font-family: "Inter-Bold";
	font-size: 42px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.14;
	letter-spacing: normal;
	color: #12141d;
}
.boxPrincing hr {
	margin: 33px 0 78px;
}
.txtAs {
	font-family: "Inter-Bold";
	font-size: 16px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.75;
	letter-spacing: normal;
	color: #12141d;
}
.numberSpan {
	font-family: "Inter-Bold";
	font-size: 42px;
	font-weight: bold;
	font-stretch: normal;
	font-style: normal;
	line-height: 1.14;
	letter-spacing: normal;
	color: #12141d;
}
.btn-green-Pricing {
	width: 249px;
	height: 55px;
	margin: 58px 198px 0 3px;
	padding: 13.5px 79px;
	border-radius: 10px;
	background-color: #14b082;
	color: #fff !important;
	cursor: pointer;
}
.bloqMargin {
	margin-bottom: 40px;
}
footer {
	color: #fff !important;
	background-color: #212827;
}
footer hr {
	margin: 16px 0 14.9px;
	border: solid 1px #52525b;
	width: 100%;
}
.imgLogoFooter {
	width: 233px;
	height: 58px;
	margin-top: 15px;
}
.col1-footer {
	width: 42%;
}
.col2-footer {
	width: 15%;
}
.col3-footer {
	width: 42%;
}
.txt-pie {
	text-align: right;
	font-size: 14px;
}
.txt-pie a {
	color: #fff;
}
.rectangle {
	width: 347px;
	height: auto;
	margin-top: -30px;
	margin-left: 200px;
	padding: 16px 16px 24px;
	object-fit: contain;
	border-radius: 12px;
	box-shadow: 0 30px 80px 0 rgba(0, 0, 0, 0.08);
	background-color: #fff;
}
.titleContact {
	font-size: 24px;
	line-height: 1.42;
	letter-spacing: 0.4px;
	color: #000;
}
footer input {
	width: 140px;
	height: 48px;
	margin: 8px 16px 16px 0;
	padding: 5px 12px 5px 12px;
	border-radius: 4px;
	border: solid 1px #d1d0d6;
	background-color: #fff;
}
footer select {
	width: auto;
	height: 55px;
	margin: 8px 0;
	padding: 5px 12px 5px 12px;
	border-radius: 4px;
	border: solid 1px #d1d0d6;
	background-color: #fff;
}
footer textarea {
	width: auto;
	height: 70px;
	margin: 8px 0;
	padding: 5px 12px 5px 12px;
	border-radius: 4px;
	border: solid 1px #d1d0d6;
	background-color: #fff;
}
.form-group {
	display: grid;
}
.btn-submit {
	width: 100%;
	margin: 0px;
}
.btn-submit.disabled {
	opacity: 0.5;
	background-color: darkgrey;
}
#tour {
	border-bottom: solid 1px rgba(32, 31, 34, 0.1);
}
.logoMenuH .logotipo {
	display: none;
}
@media screen and (max-width: 1300px) {
	.col1-Banner,
	.col2-Banner,
	.col1-About,
	.col2-About,
	.col1-Feat,
	.col2-Feat,
	.col1-Feat2,
	.col2-Feat2,
	.col1-Pricing,
	.col2-Pricing,
	.col1-Contact,
	.col2-Contact,
	.col1-footer,
	.col2-footer,
	.col3-footer {
		width: 97%;
		margin: 0 3%;
	}
	.row {
		margin-right: 0px;
		margin-left: 0px;
	}
	.logotipo {
		width: 340px;
		cursor: pointer;
	}
	.titleBanner {
		font-size: 40px;
	}
	.description {
		margin: 20px 0px 30px;
	}
	.col2-Banner img {
		margin-top: 50px;
	}
	.boxPadre {
		justify-content: center;
	}
	footer .imgBg1 {
		width: 100%;
	}
	.imgBg1 {
		width: 50%;
	}
	.imgBg2 {
		width: 25%;
	}
	.paddingBanner {
		padding: 40px 0px;
	}
	.paddingBottom {
		padding-bottom: 40px;
	}
	.paddingTop {
		padding-top: 40px;
	}
	.numberPorcentaje {
		margin: 30px 0px;
	}
	.imgAbout {
		margin: 48px 0 0 100px;
	}
	.boxFeat {
		margin: 20px 49px;
	}
	.titleH2 {
		font-size: 35px;
		margin-top: 20px;
		margin-bottom: 14px;
	}
	.paddingBloq {
		padding: 0px;
	}
	.boxCenter2 {
		padding: 0px 0px;
	}
	.iconList {
		padding-top: 20px;
	}
	.boxPrincing {
		width: 100%;
		box-sizing: border-box;
	}
	.rectangle {
		margin: 0 auto;
	}
	footer ul.navbar-nav {
		justify-content: center;
	}
	.col2-footer img {
		width: 150px;
		margin: 20px auto;
		display: block;
	}
	.txt-pie {
		text-align: center;
	}
	.linksBox {
		display: flex;
		padding-bottom: 20px;
	}
}
@media screen and (max-width: 991px) {
	.closeMenu {
		display: block;
		float: right;
		top: 10px;
		right: 10px;
		position: absolute;
		cursor: pointer;
	}
	.menuH {
		display: block;
		border: 0px;
		cursor: pointer;
		background: none;
		margin-right: 25px;
	}
	.logoMenuH {
		display: flex;
	}
	.logoMenuH .logotipo {
		width: 50%;
		margin: 20px auto;
		display: block;
		cursor: pointer;
	}
	.hamburMobile {
		width: 30px;
	}
	.logotipo {
		width: 350px;
		margin-left: 7%;
		cursor: pointer;
	}
	.leftMargin1 {
		animation-name: leftMargin1;
		animation-duration: 1s;
		animation-fill-mode: both;
	}
	@keyframes leftMargin1 {
		0% {
			left: -800px;
		}
		100% {
			left: 0px;
		}
	}
	.leftMargin2 {
		animation-name: leftMargin2;
		animation-duration: 1s;
		animation-fill-mode: both;
	}
	@keyframes leftMargin2 {
		0% {
			left: 0px;
		}
		100% {
			left: -800px;
		}
	}
	.menuMobile {
		box-sizing: border-box;
		position: fixed;
		padding: 20px;
		top: 0;
		height: 100%;
		width: 100%;
		/* left: -900px; */
		left: -950px;
		overflow-y: scroll;
		transform: all 2s ease;
		-webkit-transform: all 2s ease;
		z-index: 999999;
		background-color: #ffffff;
	}
	#overlay {
		position: fixed;
		width: 100%;
		height: 100%;
		opacity: 0;
		right: 0;
		transition: all 0.2s ease-in-out;
	}
	.overlayUp {
		opacity: 1 !important;
		z-index: 999 !important;
		top: 0px !important;
		background-color: #000000b3;
		cursor: pointer;
	}
	.menuMobile .navbar-nav {
		display: inline-block;
		text-align: center;
		width: 100%;
	}
	.menuMobile .nav-link {
		padding: 0.5rem 0.5rem;
	}
	.menuMobile .bg-btn-orange,
	.menuMobile .bg-btn-green {
		width: 100%;
		box-sizing: border-box;
		margin: 15px 0px;
	}
}
@media screen and (max-width: 767px) {
	.logotipo {
		width: 270px;
		cursor: pointer;
	}
	.logoMenuH .logotipo {
		width: 85%;
		cursor: pointer;
	}
	.imgAbout {
		width: 90%;
		height: auto;
		margin: 0 0 0 10%;
	}
	.boxCenter {
		width: 100%;
		padding: 20px 15px;
		box-sizing: border-box;
	}
	.btn-green-Banner {
		width: 100%;
		height: auto;
		margin: 0;
		display: block;
		text-align: center;
		box-sizing: border-box;
	}
	.numberPorcentaje {
		display: block;
	}
	.txtSpan {
		display: inline;
	}
	.linksBox {
		display: block;
		text-align: center;
		text-align: -webkit-center;
		text-align: -moz-center;
		text-align: -ms-center;
	}
	.boxFeat {
		width: 70%;
		margin: 10px auto;
	}
	.bloqGreen {
		width: 110px;
		bottom: 30px;
	}
	.est {
		font-size: 20px;
	}
	.ano {
		font-size: 35.1px;
	}
	.imgNotary {
		margin: 20px 0px;
	}
	.linkGreen {
		padding: 0px;
		margin-top: 13px;
		display: block;
	}
	.btn-sectionGreen {
		width: 90%;
		margin: 20px auto 0 auto;
		display: block;
		box-sizing: border-box;
		text-align: center;
	}
	.imgArrows {
		margin: 15px 0px 0px;
	}
	.btn-green-Pricing {
		display: block;
		width: 100%;
		box-sizing: border-box;
		text-align: center;
		padding: 16.5px 30px;
	}
	.boxPrincing {
		width: 97%;
		padding: 20px 20px;
	}
	footer .linksBox {
		display: flex;
	}
	.rectangle {
		margin: 0 auto;
		width: 100%;
		box-sizing: border-box;
	}
	footer form .linksBox {
		display: block;
	}
	footer input {
		width: 100%;
		box-sizing: border-box;
	}
	footer select {
		width: auto;
		height: 44px;
	}
	.nav-link {
		padding: 0.5rem 0.5rem;
	}
	.paddingBanner {
		padding: 0px 0px 40px;
	}
	#pricing {
		margin: 0 5%;
	}
	.signingService {
		font-size: 30px;
	}
	.numberSpan {
		font-size: 35px;
	}
	.plan,
	.txtAs {
		font-size: 14px;
	}
}
@media screen and (max-width: 360px) {
	.logotipo {
		width: 230px;
		margin-left: 7%;
		cursor: pointer;
	}
}
